<template>
  <div>
    <a-card style="margin-bottom: 24px">
      <div class="table-operator" style="margin-bottom: -8px">
        <a-form layout="inline">
          <a-row :gutter="0" style="line-height: 3.5">
            <a-col :md="7" :sm="24">
              <a-form-item label="门店编号">
                <a-input
                  v-model="queryParam.facilityCode"
                  placeholder="请输入"
                  @pressEnter="refetch()"
                />
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="门店名称">
                <a-input
                  v-model="queryParam.facilityName"
                  placeholder="请输入"
                  @pressEnter="refetch()"
                />
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="档口编号">
                <a-input
                  v-model="queryParam.kitchenCode"
                  placeholder="请输入"
                  @pressEnter="refetch()"
                />
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="店铺名称">
                <a-input v-model="queryParam.storeName" placeholder="请输入" @pressEnter="refetch()" />
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="合同编号">
                <a-input
                  v-model="queryParam.contractCode"
                  placeholder="请输入"
                  @pressEnter="refetch()"
                />
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button
                  style="margin-left: 8px"
                  @click="() => ((queryParam = {}), this.refetch())"
                >重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>
    <a-card>
      <a-spin :spinning="loading">
        <a-table
          rowKey="id"
          :pagination="pagination"
          :columns="columns"
          :dataSource="list"
          :scroll="{ x: 1200 }"
          @change="changePageSize"
        >
          <!-- 合同编号 -->
          <span slot="contractCode" slot-scope="text, record" class="blueColor">
            <template>
              <a @click="contractDetail(record)">{{ record.contractCode }}</a>&nbsp;&nbsp;
            </template>
          </span>
          <span slot="action" slot-scope="text, record" class="blueColor">
            <template>
              <a @click="handleDetails(record)">查看申请</a>&nbsp;&nbsp;
            </template>
          </span>
        </a-table>
      </a-spin>
    </a-card>
    <a-modal
      title="查看申请"
      :width="450"
      :visible="showDetailModal"
      @cancel="handleClose"
      :footer="null"
      destroyOnClose
    >
      <DescriptionList title size="large">
        <div style="margin-top: 16px">
          <Description term="返还政策" style="width: 100%">
            <label>{{ currentItem.refundPolicy }}</label>
          </Description>
          <Description term="返还金额" style="width: 100%">
            <label>{{ currentItem.refundAmountYuan }}</label>
          </Description>
          <Description term="返还凭证" style="width: 100%">
            <div class="imgBox">
              <img
                v-for="(item, index) in currentItem.refundCertificate"
                :key="`img${index}`"
                style="width:6em;height:6em;"
                @click="viewImg(index)"
                :src="item"
              />
            </div>
          </Description>
          <Description term="申请人" style="width: 100%">
            <label>{{ currentItem.creator }}</label>
          </Description>
          <Description term="申请时间" style="width: 100%">
            <label>{{ moment(currentItem.gmtCreatedLong).format("YYYY-MM-DD") }}</label>
          </Description>
        </div>
      </DescriptionList>
    </a-modal>
    <ImagePreviewModal
      v-if="visibleImg"
      :imageUrls="currentItem.refundCertificate[currentImageIndex]"
      :closeAction="hideImageModal"
    />
  </div>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
  
import axios from "axios";
import moment from "moment";
import { defaultPagination } from "@/utils/pagination";
import ImagePreviewModal from "@/components/ImagePreviewModal";
import { contractStatus } from "@/objects/businessObjects";

export default {
  name: "downPayRefund",
  components: {
    DescriptionList,
    Description,
    ImagePreviewModal,
  },
  data() {
    return {
      moment,
      queryParam: {
        facilityName: "",
        facilityName: "",
        kitchenCode: "",
        storeName: "",
        contractCode: "",
      },
      pagination: defaultPagination(() => {}),
      columns: [
        {
          title: "合同编号",
          key: "contractCode",
          fixed: "left",
          width: 160,
          scopedSlots: { customRender: "contractCode" },
        },
        {
          title: "城市",
          width: 80,
          dataIndex: "cityName",
          key: "cityName",
        },
        {
          title: "厨房品牌",
          width: 100,
          dataIndex: "brandName",
          key: "brandName",
        },
        {
          title: "门店编号",
          width: 100,
          dataIndex: "facilityCode",
          key: "facilityCode",
        },
        {
          title: "门店名称",
          width: 150,
          dataIndex: "facilityName",
          key: "facilityName",
        },
        {
          title: "档口编号",
          width: 100,
          dataIndex: "kitchenCode",
          key: "kitchenCode",
        },
        {
          title: "店铺名称",
          width: 150,
          dataIndex: "storeName",
          key: "storeName",
        },
        {
          title: "计租开始日",
          width: 120,
          dataIndex: "actualRentalDateLong",
          key: "actualRentalDateLong",
          customRender: (text) => {
            return moment(text).format("YYYY-MM-DD");
          },
        },
        {
          title: "合同状态",
          width: 100,
          dataIndex: "contractStatus",
          key: "contractStatus",
          customRender: (text) => {
            return contractStatus.find((s) => s.id.toString() === text).name;
          },
        },
        {
          title: "入会费返还政策",
          width: 150,
          dataIndex: "refundPolicy",
          key: "refundPolicy",
        },
        {
          title: "返还金额",
          width: 100,
          dataIndex: "refundAmountYuan",
          key: "refundAmountYuan",
        },
        {
          title: "是否已返还",
          width: 120,
          dataIndex: "statusName",
          key: "statusName",
        },
        {
          title: "操作",
          width: 100,
          fixed: "right",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      // 表格中的数据
      list: [],
      // 是否展示弹框
      showDetailModal: false,
      // 弹框中的数据
      currentItem: {},
      // 弹框中的图片index
      currentImageIndex: {},
      // 是否展示图片
      visibleImg: false,
      // 展示图片的url
      showImgUrl: null,
      // 表格数据加载中
      loading: false,
    };
  },
  created() {
    this.refetch();
  },
  computed: {},
  methods: {
    refetch(pageNumber, pageSize) {
      this.loading = true;
      axios({
        url:   this.$baseUrl + "entryfee/refund/list",
        method: "GET",
        withCredentials: true,
        params: {
          rows: pageSize || 20,
          page: pageNumber || 1,
          ...this.queryParam,
        },
      }).then((res) => {
        if (res.data.rows) {
          this.list = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.loading = false;
      });
    },
    // 换页
    changePageSize(val) {
      this.refetch(val.current, val.pageSize);
    },
    // 打开弹出窗口
    handleDetails(record) {
      this.currentItem = record;
      this.showDetailModal = true;
    },
    handleClose() {
      this.showDetailModal = false;
    },
    // 查看照片
    viewImg(index) {
      this.currentImageIndex = index || 0;
      this.visibleImg = true;
    },
    hideImageModal() {
      this.visibleImg = false;
    },
    contractDetail(record) {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: { contractDetailsId: record.contractId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.imgBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  img {
    border: 1px solid #f0f2f5;
    border-radius: 4px;
    margin: 1em;
    width: 6em;
    height: 6em;
  }
}
</style>
